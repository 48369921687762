import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { pathReplace, paths } from 'store/routePaths';
import { OpenLp, PurchasedLp, RecentLp } from './endpoints';
/**
 *
 * @param forSaleAmount number
 * @param poolBalance string
 */
export function forSaleAmount(forSaleAmount: string | number, poolBalance: string | number) {
  return Dollar(Number(forSaleAmount) * Number(poolBalance));
}
/**
 *
 * @param str string
 * @example titleCase(lpx user name)
 * // Lpx User Name
 */
export function titleCase(str: string) {
  return _.startCase(_.toLower(str));
}

type FormattingFunction = (val, opts?: Intl.NumberFormatOptions) => string;
/**
 * Percentage Converting Function
 * @param val
 * @param opts
 * @returns `string`
 * @example const newNum =  // -> 76.00%
 */
export const Percentage: FormattingFunction = (
  val,
  opts = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
) =>
  new Intl.NumberFormat('en-US', {
    ...opts,
    style: 'percent',
  }).format(val);

/**
 * US Dollar Currency Formatter
 * @param num
 * @param opts Intl.NumberFormatOptions - *optional*
 * @returns $1,000.00
 * @example  // -> $16,206,088.18
 */
export const Dollar: FormattingFunction = (val: number, opts) =>
  new Intl.NumberFormat('en-US', {
    ...opts,
    style: 'currency',
    currency: 'USD',
  }).format(val);

export interface FormattedPurchasedLp {
  id: string;
  link: string;
  seller: {
    msg: string;
    value: string;
  };
  lpIdentifier: string;
  purchaseCommitmentAmount: string;
  type: string;
  reports: {
    text: string;
    link: string;
  };
  dueDiligence: {
    text: string;
    link: string;
  };
}

export interface FormattedOpenLp {
  id: string;
  link: string;
  commitmentLink: string;
  indicatedInterest: boolean;
  indicatedInterestAmount: string;
  lpIdentifier: string;
  poolBalance: string;
  purchaseCommitment: boolean;
  purchaseCommitmentAmount: string;
  remainingBalance: string;
  type: string;
  state: string;
}
/**
 *
 * @param lps OpenLp[]
 * @returns
 * ```
 * [
  * {
      id: string;
      link: string;
      lpIdentifier: string;
      poolBalance: string;
      remainingBalance: string;
      indicatedInterestAmount: string;
      purchaseCommitmentAmount: string;
      indicatedInterest: boolean;
      purchaseCommitment: boolean;
      type: string;
  * }
 * ]
 * ```
 */
export function formatDashboardOpenLps(lps: OpenLp[]): FormattedOpenLp[] {
  const formatted = lps.map(lp => ({
    ...lp,
    id: uuid(),
    link: `lp/${lp.lpIdentifier}`,
    commitmentLink: `lp/${lp.lpIdentifier}/purchase-commitment`,
    lpIdentifier: `LP #${lp.lpIdentifier}`,
    poolBalance: `Pool Balance: ${lp.poolBalance ? Dollar(lp.poolBalance) : 'none'}`,
    remainingBalance: `Remaining Balance: ${
      lp.remainingBalance ? Dollar(lp.remainingBalance) : 'none'
    }`,
    indicatedInterestAmount: `Indicated Interest: ${
      lp.indicatedInterestAmount ? Dollar(lp.indicatedInterestAmount) : 'none'
    }`,
    purchaseCommitmentAmount: `Purchase Commitment: ${
      lp.purchaseCommitmentAmount ? Dollar(lp.purchaseCommitmentAmount) : 'none'
    }`,
  }));

  return formatted;
}

/**
 * Format Dashboard Purchased Lps Data
 *
 * @typeparam PurchasedLp[]
 * @param lps
 * @returns @type FormattedPurchsedLp[]
 */
export function formatDashboardPurchasedLps(lps: PurchasedLp[]): FormattedPurchasedLp[] {
  const formatted = lps.map(lp => ({
    ...lp,
    id: uuid(),
    link: `/lp/${lp.lpIdentifier}`,
    seller: {
      msg: `By Seller:`,
      value: lp.sellerName,
    },
    lpIdentifier: `LP #${lp.lpIdentifier}`,
    purchaseCommitmentAmount: `Purchase Committed: ${Dollar(lp.purchaseCommitmentAmount)}`,
    type: lp.type,
    reports: {
      text: 'Reports',
      link: '/reports',
    },
    dueDiligence: {
      text: 'Due Diligence Documents',
      link: pathReplace(paths.lpDueDiligence, lp.lpIdentifier),
    },
  }));

  return formatted;
}

export interface FormattedRecentLp {
  id: string;
  link: string;
  lpIdentifier: string;
  poolBalance: string;
  type: string;
  collateralDescription: string;
  wtdAverageCreditScore: string | number;
  wtdAverageLtv: number | string;
  wtdAverageLoanRate: string;
  recourseType: string;
}

export function formatDashboardRecentLps(lps: RecentLp[]): FormattedRecentLp[] {
  const maxRecentDashboardLps = 5;

  const formatted = lps
    .map(lp => ({
      id: uuid(),
      link: `lp/${lp.lpIdentifier}`,
      lpIdentifier: `LP #${lp.lpIdentifier}`,
      poolBalance: Dollar(lp.poolBalance),
      type: lp.type || '-',
      collateralDescription: lp.collateralDescription || '-',
      wtdAverageCreditScore: Math.round(Number(lp.wtdAverageCreditScore)) || '-',
      wtdAverageLtv: Percentage(lp.wtdAverageLtv),
      wtdAverageLoanRate: Percentage(lp.wtdAverageLoanRate),
      recourseType: lp.recourseType || '-',
    }))
    .slice(0, maxRecentDashboardLps);

  return formatted;
}

export const ForSalePercent = (num?: string) => (num ? parseFloat(num) * 100 + '%' : '');

export const DaysRemaining = (date?: string): any => {
  const cutOffDate = new Date(date);
  const today = new Date();
  const diffInMillisec = cutOffDate.getTime() - today.getTime();
  return Math.max(0, Math.ceil(diffInMillisec / (1000 * 60 * 60 * 24)));
};

export const LpRestrictions = ({
  WhitelistedFinancialEntities,
  BlacklistedFinancialEntities,
}: LpData) => {
  if (WhitelistedFinancialEntities) return 'Whitelist';
  if (BlacklistedFinancialEntities) return 'Blacklist';
  return 'None';
};
export type TableDateVariant = 'default' | 'simple';

export const TableDate: (date: string, variant?: TableDateVariant) => string = (
  date: string,
  variant = 'default'
) => {
  switch (variant) {
    case 'simple':
      return new Date(date).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });

    default:
      return new Date(date).toLocaleDateString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
  }
};
export const NumberOfDaysToBeInactive = 30;

export const DaysInactive = (row: User) => {
  let diff = (Date.now() - new Date(row.lastActiveDate).getTime()) / (1000 * 60 * 60 * 24);
  return Math.round(diff).toString();
};

export const LatestCASubmissionDate = (arr?: AgreementStatus[] | null) => {
  return TableDate(arr?.slice(-1)[0]?.dateOfStatus).replace('Invalid Date', 'None');
};

function isNullOrWhitespace(input) {
  return !input || !input.trim();
}

export const LatestCaStatus = (str?: string): string => {
  switch (str) {
    case 'Sent':
      return 'Submitted';
    case 'Delivered':
      return 'Viewed';
    default:
      return isNullOrWhitespace(str) ? 'None' : str;
  }
};

export function creditUnionSeparation(values: CreditUnion[]): UniqueCreditUnion[] {
  let inputs: UniqueCreditUnion[] = [];

  values.forEach(val => {
    val.rtNumbers.forEach(rt => {
      delete val.rtNumbers;
      inputs.push({ ...val, rtNumber: Number(rt) });
    });
  });

  return inputs;
}

/**
 * FORMAT TERMSHEET RESPONSE
 * @param param0
 */
export function formatTermsheet({ Data }: Termsheet): TermsheetData {
  let data: TermsheetData | Object = {};

  Data.forEach(({ TableData, TableTitle }) => {
    const title = _.camelCase(TableTitle);
    let tbleData = {};
    TableData.forEach(([key, val]) => {
      let value = val;
      if (typeof val === 'string' && Date.parse(val)) value = TableDate(val, 'simple');

      tbleData[_.camelCase(String(key))] = value;
    });
    data[title] = tbleData;
  });

  return data as TermsheetData;
}

export interface UniqueCreditUnion extends Omit<CreditUnion, 'rtNumbers'> {
  rtNumber: number;
}

/**
 * Adds Comma To Number
 * @param num string
 * format number 1000000 to 1,234,567
 */
export function formatNumber(num: string | number) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function formatCurrency(input_val = '', blur) {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.

  // don't validate empty input
  if (input_val === '') {
    return;
  }

  // original length

  // initial caret position
  // var caret_pos = input.prop('selectionStart');

  // check for decimal
  if (input_val.indexOf('.') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    var decimal_pos = input_val.indexOf('.');

    // split number by decimal point
    var left_side = input_val.substring(0, decimal_pos);
    var right_side = input_val.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // validate right side
    right_side = formatNumber(right_side);

    // On blur make sure 2 numbers after decimal
    if (blur === 'blur') {
      right_side += '00';
    }

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    // join number by .
    input_val = '$' + left_side + '.' + right_side;
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    input_val = formatNumber(input_val);
    input_val = '$' + input_val;

    // final formatting
    if (blur === 'blur') {
      input_val += '.00';
    }
  }

  // send updated string to input

  // put caret back in the right position
  // caret_pos = updated_len - original_len + caret_pos;
}

export interface AgreementStatuses {
  envelopeId: string;
  latestStatus: string;
  rejected?: null;
  dateOfStatus: string;
  type: string;
}

export interface User {
  accountEnabled: boolean;
  partitionKey: string;
  name: string;
  jobTitle: string;
  signingAuthority: boolean;
  creditUnionName: string;
  emailAddress: string;
  phoneNumber: string;
  rtNumber: string;
  lastActiveDate: string;
  cuStatus: string;
  firstActiveDate: string;
  roles: any[];
  disabled: boolean;
  creditUnionAccountId: string;
  cuAddressState: string;
  competitor: boolean;
  member: string;
  surname: string;
  givenName: string;
  partnerCu: boolean;
  agreementStatuses: AgreementStatus[];
  latestCaStatus: string;
  createdDate: string;
  sortName: string;
  userType: string;
  id: string;
  typeName: string;
  _etag: string;
}

export interface AgreementStatus {
  envelopeId: string;
  latestStatus: string;
  rejected: boolean;
  dateOfStatus: string;
  type: string;
}

export interface PurchaseCommitment {
  partitionKey: string;
  TypeName: string;
  UserObjectId: string;
  LPNumber: string;
  CreditUnionAccountId: string;
  CreditUnionName: string;
  TotalAssets: string;
  LoanSlashShare: string;
  State: string;
  RtNumber: string;
  Name: string;
  Title: string;
  Amount: string;
  DateSubmitted: string;
  SellerName: null;
  id: string;
  _etag: string;
}

export interface IndicationOfInterest {
  partitionKey: string;
  TypeName: string;
  UserObjectId: string;
  LPNumber: string;
  CreditUnionAccountId: string;
  CreditUnionName: string;
  TotalAssets: string;
  LoanSlashShare: string;
  State: string;
  RtNumber: string;
  Name: null | string;
  Title: null | string;
  Amount: string;
  DateSubmitted: string;
  id: string;
  _etag: string;
}

export interface LpData {
  partitionKey: string;
  State: string;
  LastStateChangeDate: null;
  SoldOut: null;
  DateAdded: null | string;
  DueDiligenceStart: null | string;
  DueDiligenceDeadline: null | string;
  CutOffDate: null | string;
  SettlementDate: null | string;
  IndicationsOfInterestTotal: string;
  PurchaseCommitmentsTotal: string;
  LoanType: null | string;
  PoolBalance: null | string;
  AmountAvailableForSalePercent: null | string;
  RetainedBySeller: null | string;
  AmountRemaining: null | string;
  Restrictions: null;
  SellerName: null | string;
  IndicationsOfInterest: IndicationsOfInterest[] | null;
  PurchaseCommitments: IndicationsOfInterest[] | null;
  Basis: null | string;
  WtdAvgLoanRate: null | string;
  WtdAvgCreditScore: null | string;
  WtdAverageLtv: null | string;
  BlacklistedFinancialEntities: ListedFinancialEntity[] | null;
  WhitelistedFinancialEntities: ListedFinancialEntity[] | null;
  id: string;
  TypeName: string;
  _etag: string;
}

export interface ListedFinancialEntity {
  Name: string;
  SalesforceAccountId: string;
  State: string;
  RelatedRtNumbers: string[];
}

export interface IndicationsOfInterest {
  Amount: string;
  CreditUnionAccountId: string;
  CreditUnionName: null;
  RtNumber: string;
  UserObjectId: string;
  IndicatedTime: string;
}
export type Interest = {
  partitionKey: string;
  TypeName: string;
  UserObjectId: string;
  LPNumber: string;
  CreditUnionAccountId: string;
  CreditUnionName: string;
  TotalAssets: string;
  LoanSlashShare: string;
  State: string;
  RtNumber: string;
  Name: null | string;
  Title: null | string;
  Amount: string;
  DateSubmitted: string;
  id: string;
  _etag: string;
};

export interface TermsheetData {
  portfolioSummary: { [key: string]: number };
  stratificationByCreditScore: { [key: string]: number };
  stratificationByCurrentBalance: { [key: string]: number };
  stratificationByOriginalTerm: StratificationByOriginalTerm;
  stratificationByRemainingTerm: { [key: string]: number };
  stratificationByRate: { [key: string]: number };
  stratificationByPropertyDescription: StratificationByPropertyDescription;
  stratificationByOccupancy: StratificationByOccupancy;
  stratificationByLoanCategory: StratificationByLoanCategory;
  stratificationByLtvOrigination: { [key: string]: number };
  stratificationByCombinedLtv: { [key: string]: number };
  stratificationByCurrentLtv: { [key: string]: number };
  stratificationByBottomDebtRatio: { [key: string]: number };
  stratificationByTopDebtRatio: { [key: string]: number };
  stratificationByGeographicConcentration: StratificationByGeographicConcentration;
  lpxSummary: LpxSummary;
  collateralComposition: { [key: string]: null | string };
  yieldMatrix: YieldMatrix;
  loanParticipationHighlights: { [key: string]: null };
  loanParticipationDetails: { [key: string]: null | string };
  settlement: { [key: string]: null };
  loanParticipationInstructionsAndInformation: { [key: string]: null | string };
  riskConsiderations: { [key: string]: null | string };
  importantDisclosures: { [key: string]: null };
  disclaimer: Disclaimer;
}

export interface Disclaimer {
  '1': null;
}

export interface LpxSummary {
  loanParticipationPortfolio: string;
  sellerName: string;
  loanType: string;
  collateralDescription: string;
  poolBalanceAsOf: Date;
  retainedBySeller: number;
  availableForSale: number;
  ofLoans: number;
  dueDiligenceStart: Date;
  dueDiligenceDeadline: Date;
  basis: string;
  wtdAvgLoanPoolIntRate: number;
  servicing: number;
  prepaySpeed: string;
  lpYield: number;
  chargeOffs: number;
  purchasePrice: number;
  projectedSettlementDate: Date;
  settlementDate: Date;
  minimumPurchaseAmount: number;
  amountRemaining: number;
}

export interface StratificationByGeographicConcentration {
  southwest: number;
  null: number;
}

export interface StratificationByLoanCategory {
  '15YearFixed': number;
  '30YearFixed': number;
  null: number;
}

export interface StratificationByOccupancy {
  primaryResidence: number;
  null: number;
}

export interface StratificationByOriginalTerm {
  '180': number;
  '360': number;
  null: number;
}

export interface StratificationByPropertyDescription {
  condo: number;
  pud: number;
  singleFamily: number;
  null: number;
}

export interface YieldMatrix {
  '101685': string;
  noLoss: number;
  '1XAnnualizedNco': number;
  '15XAnnualizedNco': number;
  '2XAnnualizedNco': number;
  averageLifeYrs: number;
}

export interface Termsheet {
  Data?: Datum[];
  HeaderData?: HeaderData;
  Title?: string;
  LPIdentifier?: string;
  SpFileName?: string;
  SpVersion?: number;
}

export interface Datum {
  TableHeader?: TableHeader[];
  TableData?: Array<Array<number | null | string>>;
  TableDataAsObject?: TableDataAsObject[];
  TableTitle?: string;
  SourceSheet?: SourceSheet;
}

export enum SourceSheet {
  LPXInputSheet = 'LPX Input Sheet',
  Strats = 'Strats',
}

export interface TableDataAsObject {
  Description?: string;
  Value?: number | null | string;
  Order?: number;
  Name?: null | string;
  YieldType?: number | string;
  Value0?: number | string;
  Value10?: number | string;
  Value20?: number | string;
  Value25?: number | string;
  Value30?: number | string;
  HighlightLine?: number;
  HighlightPrefix?: null;
  HighlightText?: string;
  HighlightPostfix?: null;
  DetailLine?: number;
  DetailPrefix?: null | string;
  DetailText?: string;
  DetailPostfix?: null;
  SettleLine?: number;
  SettlePrefix?: null;
  SettleText?: string;
  SettlePostfix?: null;
  InstructionLine?: number;
  InstructionPrefix?: null | string;
  InstructionText?: null | string;
  InstructionPostfix?: null;
  RiskLine?: number;
  RiskPrefix?: null | string;
  RiskText?: null | string;
  RiskPostfix?: null;
  DisclosureLine?: number;
  DisclosurePrefix?: null;
  DisclosureText?: string;
  DisclosurePostfix?: null;
  DisclaimerLine?: number;
  DisclaimerPrefix?: null;
  DisclaimerText?: string;
  DisclaimerPostfix?: null;
  Minimum?: number;
  'Average / Weighted Average'?: number;
  Maximum?: number;
  'Credit Score Level'?: null | string;
  '# of Loans'?: number;
  'Current Balance'?: number;
  '% of Loan'?: number;
  'Weighted Avg Rate'?: number | null;
  'Weighted Avg Credit Score'?: number;
  'Weighted Avg Rem Term'?: number;
  'Weighted Avg LTV'?: number | null;
  'Current Balance of Loan'?: null | string;
  'No. Months in Original Term'?: null | string;
  'No. Months Remaining'?: null | string;
  'Interest Rate'?: null | string;
  'Model Year'?: number | null;
  LTV?: null | string;
  'Loan Type'?: null | string;
  'Debt Ratio'?: null | string;
  Region?: null | string;
}

export interface TableHeader {
  ColumnNumber?: number;
  Value?: string;
}

export interface HeaderData {
  'Pool Size'?: number;
  'WA Gross Coupon'?: number;
  'Loan Count'?: number;
  'Servicing Fee'?: number;
  'Cut-off Date'?: Date;
  'WA Net Coupon'?: number;
  'Avg Loan Bal'?: number;
  'WA Orig Term'?: number;
  'Avg Orig Loan Bal'?: number;
  'WA Rem Term'?: number;
  'WA Credit Score'?: number;
  'WA Age'?: number;
}

export interface FileDownload {
  itemUniqueId?: string;
  itemName?: string;
  contentType?: string;
  parentContentType?: string;
  section?: null | string;
  exists?: boolean;
  isTermSheet?: boolean;
  version?: number;
  views?: string[];
  tags?: string[];
  restrictions?: string[];
  library?: string;
  attributes?: Attributes;
  path?: string;
  relatedLP?: string;
  cuFile?: null;
  dateReceived?: string;
}

export interface Attributes {
  'Loan Pool'?: string;
  'Seller RT Number'?: string;
  'Buyer RT Number'?: null;
}

export interface CreditUnion {
  name: string | '';
  salesForceAccountId: string | '';
  state: string | '';
  rtNumbers: string[] | [];
  partner?: boolean | null;
}

export interface UserManagementUser {
  objectId?: string;
  state?: null;
  userName?: string;
  city?: null;
  displayName?: string;
  givenName?: string;
  emailAddress?: string;
  jobTitle?: string;
  postalCode?: null;
  surname?: string;
  userIsNew?: null;
  rtNumber?: string;
  creditUnionName?: string;
  accountEnabled?: boolean;
  signingAuthority?: string;
  streetAddress?: null;
  phoneNumber?: string;
  cellPhone?: string;
  creditUnionAccountId?: string;
  disableUpload?: null;
  disableReports?: null;
  createdDate?: string;
  smsEnabled?: null;
  extendedValues?: ExtendedValues;
}

export interface ExtendedValues {
  ageGroup?: string;
  assignedLicenses?: string;
  assignedPlans?: string;
  consentProvidedForMinor?: string;
  passwordPolicies?: string;
  passwordProfile?: string;
  provisionedPlans?: string;
  provisioningErrors?: string;
  proxyAddresses?: string;
  'thumbnailPhoto@odata.mediaEditLink'?: string;
  userIdentities?: string;
  extension_80d70ece8f404d94956d642d81767356_CellPhone?: string;
  extension_80d70ece8f404d94956d642d81767356_Phone?: string;
  extension_80d70ece8f404d94956d642d81767356_Routing?: string;
  extension_80d70ece8f404d94956d642d81767356_Credit?: string;
  extension_80d70ece8f404d94956d642d81767356_CreditUnionAccountId?: string;
  extension_80d70ece8f404d94956d642d81767356_SigningAuthority?: string;
}

export interface Notification {
  partitionKey?: string;
  lastUpdate?: string;
  userId?: string;
  smsNumber?: string;
  NotificationPreferences?: NotificationPreference[];
  id?: string;
  _etag?: string;
  typeName?: string;
  version?: number;
}

export interface NotificationPreference {
  category?: string;
  name?: string;
  availableDestinations?: AvailableDestination[] | null;
  emailEnabled?: boolean;
  smsEnabled?: boolean;
  description?: null | string;
}

export enum AvailableDestination {
  Email = 'EMAIL',
  SMS = 'SMS',
}
