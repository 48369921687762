import { UserPermissionsForm } from './UserPermissionsForm';
import React, { Fragment } from 'react';
import {
  Checkbox,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { LPButton } from '../../../components/Common/LPButton';
import LPLinkPlain from '../../../components/Common/LPLinkPlain';
import { pathReplace, paths } from '../../../store/routePaths';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationTable: {
      color: theme.colors.white,
      '& thead tr th': {
        fontSize: '18px !important',
        color: theme.colors.white,
        textTransform: 'uppercase',
        borderBottom: 'none',
        fontWeight: 'bold',
      },
      '& tbody tr': {
        '& td': {
          fontSize: '18px !important',
          color: theme.colors.white,
          paddingLeft: 25,
          borderBottom: 'none',
          '& h6': {
            fontSize: '1.0em',
          },
          '& span': {
            fontSize: '0.75em',
          },
          '& > *': {
            paddingLeft: '0px !important',
          },
          '&:first-child': {
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
          },
          '&:last-child': {
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
          },
        },
        '& td:first-child': {
          width: '100%',
        },
      },
      '& tbody tr:nth-child(odd)': {
        backgroundColor: '#3c82d6',
        borderRadius: 5,
      },
    },
    verifyUserButton: {
      textAlign: 'center',
      marginTop: 20,
    },
    forgotPasswordLink: {
      color: theme.colors.white,
      textDecoration: 'underline',
      textAlign: 'center',
      fontSize: '1.15em',
    },
  })
);

export const NotificationsOrAdminPermissions = props => {
  const {
    user,
    currentUser,
    notification,
    toggleNotification,
    togglePermission,
    isUserVerified,
  } = props;
  const classes = useStyles(props);

  if (currentUser.admin) {
    return (
      <Fragment>
        {!isUserVerified && user.accountEnabled && (
          <LPLinkPlain
            to={pathReplace(paths.adminNewUserVerificationPage, props.match.params.id)}
            className={classes.verifyUserButton}
          >
            <LPButton color="primary" type="submit" variant="contained">
              VERIFY THE USER
            </LPButton>
          </LPLinkPlain>
        )}
        <PermissionsWrapper>
          <UserPermissionsForm user={user} togglePermission={togglePermission} />
        </PermissionsWrapper>
      </Fragment>
    );
  }

  if (!currentUser.admin) {
    return (
      <Fragment>
        <LPLinkPlain
          hitServer={true}
          to={paths.forgotPassword}
          className={classes.forgotPasswordLink}
        >
          Reset your password
        </LPLinkPlain>
        <TableWrapper>
          <div className="header">
            <Typography variant="h4">Edit Your Notifications</Typography>
            <Typography variant="subtitle1">Let us know what you want to be updated on.</Typography>
          </div>

          <Table className={classes.notificationTable}>
            <TableHead>
              <TableRow>
                <TableCell>Notification</TableCell>
                <TableCell>sms</TableCell>
                <TableCell>email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notification.NotificationPreferences.map(
                (
                  { name, emailEnabled, smsEnabled, description, category, availableDestinations },
                  index
                ) =>
                  category !== 'Hidden' && (
                    <TableRow key={uuid()}>
                      <TableCell>
                        <Typography variant="subtitle1">{name}</Typography>
                        <Typography variant="caption">{description}</Typography>
                      </TableCell>
                      <TableCell>
                        {availableDestinations.includes('SMS') && (
                          <Checkbox
                            checked={smsEnabled}
                            onClick={() => toggleNotification(index, 'smsEnabled')}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {availableDestinations.includes('EMAIL') && (
                          <Checkbox
                            checked={emailEnabled}
                            onClick={() => toggleNotification(index, 'emailEnabled')}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableWrapper>
      </Fragment>
    );
  }
};

const TableWrapper = styled.div`
  background-color: #185daf;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.16);
  padding: 40px;
  width: 100%;
  max-width: 800px;
  margin: 50px auto;

  .header {
    margin-bottom: 20px;
    h4 {
      font-size: 2.75em;
      font-weight: bold;
    }
    h6 {
      font-style: italic;
    }
  }
`;

const PermissionsWrapper = styled.div`
  margin: 50px auto;
  width: 100%;
`;
