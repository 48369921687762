import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Header } from './Header';
import LoadingSpinner from 'components/data/LoadingSpinner';
const OpenLps = React.lazy(() => import('./OpenLps'));
const PurchasedLps = React.lazy(() => import('./PurchasedLps'));
const RecentLps = React.lazy(() => import('./RecentLps'));

const DashboardPage: React.FC = () => (
  <>
    <Header />
    <Wrapper>
      <section>
        <React.Suspense fallback={<LoadingSpinner />}>
          <OpenLps>
            <Title>open lp&apos;s</Title>
          </OpenLps>
        </React.Suspense>
      </section>

      <section>
        <React.Suspense fallback={<LoadingSpinner />}>
          <RecentLps>
            <Title>recent lp&apos;s</Title>
          </RecentLps>
        </React.Suspense>
      </section>

      <section>
        <React.Suspense fallback={<LoadingSpinner />}>
          <PurchasedLps>
            <Title>Committed/Purchased LP&apos;s</Title>
          </PurchasedLps>
        </React.Suspense>
      </section>
    </Wrapper>
  </>
);

export const Title = styled(Typography)`
  font-weight: 900 !important;
  color: ${({ theme }) => theme.colors.sectionTitle};
  text-transform: capitalize;
  margin: 0;
  margin-bottom: 1em;
  padding: 0;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  li {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
`;

Title.defaultProps = {
  variant: 'h4',
  style: { width: 'max-content' },
};

const Wrapper = styled.div`
  margin: 2em 0;

  section {
    padding: 0 1em;
  }
  .actions {
    text-align: center;
  }
`;

export default DashboardPage;
