import styled from 'styled-components';

const LPTitle = styled.h1`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  height: 49px;
  opacity: 0.75;
  font-size: 46px;
  font-weight: 500;
  line-height: 1.23;
  text-align: left;
  color: ${props => props.theme.colors.peacockBlue};
  margin: 0;
`;

export default LPTitle;
