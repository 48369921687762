import React from 'react';
import { LPTableProps, OrderDirection } from './types';
import { TableProvider } from './table-context';
import { LPTableContainer } from './LPTableContainer';

export const LPTable: React.FC<LPTableProps> = props => {
  const {
    headers,
    defaultOrdering = {
      direction: 'asc' as OrderDirection,
      field: headers[0].keyOrGetter,
      label: headers[0].label,
    },
  } = props;

  return (
    <TableProvider defaultOrdering={defaultOrdering}>
      <LPTableContainer {...props} />
    </TableProvider>
  );
};
